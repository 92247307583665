import React, { FunctionComponent, useRef, useEffect } from 'react'
import styles from './Picker.module.css'

interface PickerProps {
  label: string
  options: {
    name: string
    index: string
  }[]
  setter: (value: string) => void
  deselect?: boolean
  resetDeselect?: () => void
}

const Picker: FunctionComponent<PickerProps> = (props: PickerProps) => {
  const now = Date.now()
  const selecRef = useRef<HTMLSelectElement>(null)
  const { deselect, resetDeselect } = props
  useEffect(() => {
    if (deselect) {
      if (selecRef.current) {
        selecRef.current.selectedIndex = 0
        if (resetDeselect) {
          resetDeselect()
        }
      }
    }
  }, [deselect, resetDeselect])
  return (
    <div className={styles.picker}>
      <label htmlFor={`picker-${now}`}>{props.label}:</label>
      <select
        name="select"
        id={`picker-${now}`}
        defaultValue="header"
        onChange={(ev) => props.setter(ev.target.value)}
        ref={selecRef}
      >
        <option value="header" disabled>{`Choose ${props.label}`}</option>
        {props.options.map((elem, index) => (
          <option value={elem.index} key={index}>
            {elem.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Picker
