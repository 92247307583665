import React, { FunctionComponent } from 'react'
import styles from './Error.module.css'

interface ErrorProps {
  message: string
}

const Error: FunctionComponent<ErrorProps> = (props) => {
  return <div className={styles.errorBox}>{props.message}</div>
}

export default Error
