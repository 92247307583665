import React, { useState, useContext } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import Graph, { graphData } from './Graph'
import { GlobalContext, ContextType } from './GlobalContextProvider'
import Error from './Error'

type Props = {
  closeDialog: () => void
}

interface valueHistoryData {
  getHistoricValues: {
    date: string
    value: number
  }[]
}

const ValueHistory = (props: Props) => {
  const getHistoricValues = gql`
    query ValueHistory($days: Int!) {
      getHistoricValues(days: $days) {
        date
        value
      }
    }
  `
  const [period, setPeriod] = useState(31)

  const { token } = useContext(GlobalContext) as ContextType
  const { data, loading, error } = useQuery<valueHistoryData>(
    getHistoricValues,
    {
      variables: { days: period },
      context: { headers: { authorization: `Bearer ${token}` } },
    }
  )

  if (error) {
    return <Error message={error.message} />
  }
  let points: graphData[] = []
  if (data !== undefined) {
    points = data.getHistoricValues.map((entry) => ({
      date: entry.date,
      value: entry.value,
    }))
  }
  return (
    <div>
      <Graph
        name="Historic Portfolio Valuation"
        graphData={points}
        period={period}
        changeDays={(days) => setPeriod(days)}
        closeDialog={() => props.closeDialog()}
        loading={loading || data === undefined}
        percentage={false}
      />
    </div>
  )
}

export default ValueHistory
