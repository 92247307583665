import React, { useState } from 'react'
import styles from './Portfolio.module.css'
import { format } from './Utils'
import FundHistory from './FundHistory'
import { PortfolioType } from './Types'

type Props = {
  portfolio: PortfolioType
}

const Portfolio = (props: Props) => {
  let totalCurrent = 0
  let totalStart = 0
  const [selectedFund, setSelectedFund] = useState<string>('')

  return (
    <div className={styles.portfolio}>
      {selectedFund !== '' ? (
        <FundHistory fund={selectedFund} setSelect={setSelectedFund} />
      ) : null}
      <h1>{props.portfolio.name}</h1>
      <div className={`${styles.fundLine} ${styles.columnHeader}`}>
        <span></span>
        <span>Current</span>
        <span>Original</span>
        <span>Change</span>
      </div>
      {props.portfolio.investments.map((fund, index) => {
        totalCurrent += fund.currentValue
        totalStart += fund.startValue
        if (fund.currentValue === 0) {
          return null
        }
        return (
          <div className={styles.fundLine} key={index}>
            <div
              className={`${styles.fundName} ${styles.clickable}`}
              onClick={(e) =>
                setSelectedFund((e.target as HTMLDivElement).dataset.fund!)
              }
              data-fund={fund.fundId}
            >
              {fund.fundName}
            </div>
            <div className={styles.value}>{format(fund.currentValue)}</div>
            <div className={styles.value}>{format(fund.startValue)}</div>
            <div className={styles.value}>
              {format(fund.currentValue - fund.startValue)}
            </div>
          </div>
        )
      })}
      <div className={`${styles.fundLine} ${styles.total}`}>
        <div className={styles.fundName}>Total</div>
        <div className={styles.value}>{format(totalCurrent)}</div>
        <div className={styles.value}>{format(totalStart)}</div>
        <div className={styles.value}>{format(totalCurrent - totalStart)}</div>
      </div>
    </div>
  )
}

export default Portfolio
