import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useContext,
} from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import Picker from './Picker'
import Loading from './Loading'
import Error from './Error'
import Updating from './Updating'
import Updated from './Updated'
import Navigation from './Navigation'
import { GlobalContext, ContextType } from './GlobalContextProvider'
import styles from './Purchase.module.css'

const GET_DATA = gql`
  query {
    getPortfolios {
      name
      portfolioId
      commenced
      linkedId
    }
    getFundList {
      fundId
      name
    }
  }
`

const GET_PRICE = gql`
  query ($fundId: String!, $date: String!, $share: Float!) {
    getPrice(fundId: $fundId, date: $date, share: $share) {
      units
      unitPrice
      totalPrice
    }
  }
`

const ADD_PURCHASE = gql`
  mutation (
    $portfolioId: Int!
    $date: String!
    $fundId: String!
    $shares: Float!
    $unitPrice: Float!
    $commission: Float!
  ) {
    addPurchase(
      portfolioId: $portfolioId
      date: $date
      fundId: $fundId
      shares: $shares
      unitPrice: $unitPrice
      commission: $commission
    ) {
      portfolioId
      date
      fundId
      shares
      unitPrice
      commission
    }
  }
`

interface getData {
  getPortfolios: {
    name: string
    portfolioId: number
    commenced: boolean
    linkedId: number
    portfolios: {
      startDate: string
      funds: {
        fundId: string
        shares: string
      }[]
    }[]
  }[]
  getFundList: {
    fundId: string
    name: string
  }[]
}

interface getPriceData {
  getPrice: {
    units: number
    unitPrice: number
    totalPrice: number
  }
}

interface addPurchaseData {
  portfolioId: number
  date: string
  fundId: string
  shares: number
  commission: number
}

interface PurchaseProps {}

const Purchase: FunctionComponent<PurchaseProps> = (props: PurchaseProps) => {
  const today = new Date()
  const [purchaseDate, setPurchaseDate] = useState<string>(
    today.toISOString().substr(0, 10)
  )
  const [portfolioFrom, setPortfolioFrom] = useState<string>('')
  const [purchaseFund, setPurchaseFund] = useState<string>('')
  // const [deselect, setDeselect] = useState<boolean>(false)
  const amountRef = useRef<HTMLInputElement>(null)
  const commissionRef = useRef<HTMLInputElement>(null)
  const overrideRef = useRef<HTMLInputElement>(null)
  const [sharePriceDisplay, setSharePriceDisplay] = useState<string>('')
  const [sharePrice, setSharePrice] = useState<number>(0)
  const [shareNumber, setShareNumber] = useState<number>(0)
  const [commission, setCommission] = useState<number>(0)
  const typingTimer = useRef<NodeJS.Timeout>()
  const { token } = useContext(GlobalContext) as ContextType
  const getDataQuery = useQuery<getData>(GET_DATA, {
    context: { headers: { authorization: `Bearer ${token}` } },
  })
  const [deselect, setDeselect] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [updated, setUpdated] = useState<boolean>(false)
  const [getPrice, getPriceQuery] = useLazyQuery<getPriceData>(GET_PRICE, {
    context: { headers: { authorization: `Bearer ${token}` } },
  })
  const [addPurchase, addPurchaseMutation] = useMutation<addPurchaseData>(
    ADD_PURCHASE,
    {
      context: { headers: { authorization: `Bearer ${token}` } },
    }
  )

  useEffect(() => {
    if (getPriceQuery.data) {
      setSharePriceDisplay(
        `@£${getPriceQuery.data.getPrice.unitPrice.toFixed(
          4
        )} = ${getPriceQuery.data.getPrice.totalPrice.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP',
        })}`
      )
      setSharePrice(getPriceQuery.data.getPrice.unitPrice)
      setShareNumber(getPriceQuery.data.getPrice.units)
    }
  }, [getPriceQuery.data])

  useEffect(() => {
    if (addPurchaseMutation.loading) {
      setUpdating(true)
    }
    if (addPurchaseMutation.data) {
      setPurchaseFund('')
      setDeselect(true)
      setUpdating(false)
      setUpdated(true)
      setSharePriceDisplay('')
      setSharePrice(0)
      setShareNumber(0)
      setCommission(0)
      if (amountRef.current && commissionRef.current && overrideRef.current) {
        amountRef.current.disabled = true
        amountRef.current.value = ''
        commissionRef.current.disabled = true
        commissionRef.current.value = ''
        overrideRef.current.disabled = true
        overrideRef.current.value = ''
      }
    }
  }, [addPurchaseMutation.data, addPurchaseMutation.loading])

  if (getDataQuery.loading) return <Loading />
  if (getDataQuery.error) return <Error message={getDataQuery.error.message} />
  if (getDataQuery.data === undefined) {
    return <Error message="No data found" />
  }
  // data is now loaded
  const portfolioOptions = getDataQuery.data.getPortfolios.map((elem) => {
    return {
      name: elem.name,
      index: elem.portfolioId.toString(),
    }
  })
  const fundOptions = getDataQuery.data.getFundList.map((elem) => {
    return {
      name: elem.name,
      index: elem.fundId,
    }
  })

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    addPurchase({
      variables: {
        portfolioId: parseInt(portfolioFrom),
        date: purchaseDate,
        fundId: purchaseFund,
        shares: shareNumber,
        unitPrice: sharePrice,
        commission: commission,
      },
    })
  }
  const handleDateChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setPurchaseDate(event.target.value)
  }
  const portfolioFromPicked = (value: string) => {
    setPortfolioFrom(value)
    if (
      purchaseFund !== '' &&
      amountRef.current &&
      commissionRef.current &&
      overrideRef.current
    ) {
      amountRef.current.disabled = false
      commissionRef.current.disabled = false
      overrideRef.current.disabled = false
    }
  }
  const fundPicked = (value: string) => {
    setPurchaseFund(value)
    if (
      portfolioFrom !== '' &&
      amountRef.current &&
      commissionRef.current &&
      overrideRef.current
    ) {
      amountRef.current.disabled = false
      commissionRef.current.disabled = false
      overrideRef.current.disabled = false
    }
  }
  const handleAmountChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (typingTimer.current) {
      clearTimeout(typingTimer.current)
    }
    typingTimer.current = setTimeout(() => {
      getPrice({
        variables: {
          fundId: purchaseFund,
          date: purchaseDate,
          share: parseFloat(event.target.value),
        },
      })
    }, 1500)
  }

  const clearUpdated = () => setUpdated(false)

  const handleCommissionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setCommission(parseFloat(event.target.value))
    }
  }

  const handleOverrideChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setSharePrice(parseFloat(event.target.value))
    }
  }

  const dateStr = purchaseDate

  return (
    <div className={styles.purchase}>
      <Navigation />
      <h1>Buy Funds</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formRow}>
          <label htmlFor="date">Date:</label>
          <input
            type="date"
            id="date"
            onChange={handleDateChanged}
            value={dateStr}
          />
        </div>
        <div className={styles.formRow}>
          <Picker
            label="Portfolio"
            options={portfolioOptions}
            setter={portfolioFromPicked}
          />
        </div>
        <div className={styles.formRow}>
          <Picker
            label="Fund to purchase"
            options={fundOptions}
            setter={fundPicked}
            deselect={deselect}
            resetDeselect={() => setDeselect(false)}
          />
        </div>
        <div className={`${styles.formRow} ${styles.amountBox}`}>
          <div className={styles.sharePrice}>
            <label htmlFor="amount">Number of shares:</label>
            <span>{sharePriceDisplay}</span>
          </div>
          <input
            type="number"
            id="amount"
            onChange={handleAmountChanged}
            step="0.0001"
            disabled={true}
            ref={amountRef}
          />
        </div>
        <div className={`${styles.formRow} ${styles.amountBox}`}>
          <label htmlFor="commission">Commission:</label>
          <input
            type="number"
            id="commission"
            onChange={handleCommissionChanged}
            step="0.0001"
            disabled={true}
            ref={commissionRef}
          />
        </div>
        <div className={`${styles.formRow} ${styles.amountBox}`}>
          <label htmlFor="override">Override:</label>
          <input
            type="number"
            id="override"
            onChange={handleOverrideChanged}
            step="0.0001"
            disabled={true}
            ref={overrideRef}
          />
        </div>
        <div className={styles.total}>
          Total:{' '}
          {(sharePrice * shareNumber + commission).toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
          })}
        </div>
        <div className={styles.formRow}>
          <input type="submit" value="Apply changes" id="submitButton" />
        </div>
      </form>
      {updating && <Updating />}
      {updated && (
        <Updated
          message="Commencement added successfully"
          clearer={clearUpdated}
        />
      )}
    </div>
  )
}

export default Purchase
