import React from 'react'
import styles from './DialogClose.module.css'

type Props = {
  closeFunction: React.MouseEventHandler<HTMLDivElement>
}

const DialogClose = (props: Props) => {
  return (
    <div className={styles.dialogClose} onClick={props.closeFunction}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#ffffff"
          fillOpacity="1"
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
      </svg>
    </div>
  )
}

export default DialogClose
