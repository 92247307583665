import React, {
  FunctionComponent,
  ChangeEvent,
  FormEvent,
  useState,
  useEffect,
  useContext,
} from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import Picker from './Picker'
import Loading from './Loading'
import Error from './Error'
import Updating from './Updating'
import Updated from './Updated'
import Navigation from './Navigation'
import { GlobalContext, ContextType } from './GlobalContextProvider'
import styles from './Commence.module.css'

const GET_PORFOLIOS = gql`
  query {
    getPortfolios {
      name
      portfolioId
      commenced
      linkedId
    }
  }
`
const ADD_COMMENCEMENT = gql`
  mutation AddCommencement(
    $portfolioId: Int!
    $date: String!
    $fundRecs: [FundRecsInput!]!
  ) {
    addCommencement(
      portfolioId: $portfolioId
      date: $date
      fundRecs: $fundRecs
    ) {
      portfolioId
      date
      funds {
        fundId
        shares
      }
    }
  }
`

const GET_FUNDS_FOR_DATE = gql`
  query ($portfolioId: Int!, $date: String!) {
    getFundsForDate(portfolioId: $portfolioId, date: $date) {
      name
      fundId
    }
  }
`

interface getPortfolioData {
  getPortfolios: {
    name: string
    portfolioId: number
    commenced: boolean
    linkedId: number
  }[]
}

interface addCommencementData {
  addCommencement: {
    portfolioId: number
    date: string
    funds: {
      fundId: number
      shares: number
    }[]
  }
}

interface pickerOptions {
  name: string
  index: string
}

interface fundAmounts {
  fundId: string
  shares: number
}

interface getFundsForDateData {
  getFundsForDate: {
    name: string
    fundId: string
  }[]
}

const Commence: FunctionComponent = () => {
  const today = new Date()
  const [commenceDate, setCommenceDate] = useState<string>(
    today.toISOString().substr(0, 10)
  )
  const [portfolioFrom, setPortfolioFrom] = useState<string>('')
  const [commenceFunds, setCommenceFunds] = useState<pickerOptions[]>([])
  const [commenceAmount, setCommenceAmount] = useState<fundAmounts[]>([])
  const [deselect, setDeselect] = useState<boolean>(false)
  const { token } = useContext(GlobalContext) as ContextType
  const { data, loading, error } = useQuery<getPortfolioData>(GET_PORFOLIOS, {
    context: { headers: { authorization: `Bearer ${token}` } },
  })
  const [addCommencement, addCommencementMutation] =
    useMutation<addCommencementData>(ADD_COMMENCEMENT, {
      context: { headers: { authorization: `Bearer ${token}` } },
    })
  const [updating, setUpdating] = useState<boolean>(false)
  const [updated, setUpdated] = useState<boolean>(false)
  const [getFundsForDate, getFundsForDateQuery] =
    useLazyQuery<getFundsForDateData>(GET_FUNDS_FOR_DATE, {
      context: { headers: { authorization: `Bearer ${token}` } },
    })
  useEffect(() => {
    if (addCommencementMutation.loading) {
      setUpdating(true)
    }
    if (addCommencementMutation.data) {
      setCommenceAmount([])
      setCommenceFunds([])
      setPortfolioFrom('')
      setDeselect(true)
      setUpdating(false)
      setUpdated(true)
    }
    if (addCommencementMutation.error) {
      setCommenceAmount([])
      setCommenceFunds([])
      setPortfolioFrom('')
      setDeselect(true)
      setUpdating(false)
      setUpdated(true)
    }
  }, [
    addCommencementMutation.data,
    addCommencementMutation.loading,
    addCommencementMutation.error,
  ])
  useEffect(() => {
    if (getFundsForDateQuery.data) {
      const fundsToCommence = getFundsForDateQuery.data.getFundsForDate.map(
        (el) => {
          return {
            name: el.name,
            index: el.fundId,
          }
        }
      )
      setCommenceFunds(fundsToCommence)
    }
  }, [getFundsForDateQuery.data])
  if (loading) return <Loading />
  if (error) return <Error message={error.message} />
  if (addCommencementMutation.error)
    return <Error message={addCommencementMutation.error.message} />
  if (data === undefined) {
    return <Error message="No data found" />
  }
  // data is now loaded
  const portfolioCanCommence = data.getPortfolios.filter(
    (elem) => !elem.commenced
  )
  const portfolioOptions = portfolioCanCommence.map((elem) => {
    return {
      name: elem.name,
      index: elem.portfolioId.toString(),
      linkedID: elem.linkedId,
    }
  })
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    addCommencement({
      variables: {
        portfolioId: parseInt(portfolioFrom),
        date: commenceDate,
        fundRecs: commenceAmount,
      },
    }).catch((err) => console.log(`received error :: ${err.message}`))
  }
  const handleDateChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setCommenceDate(event.target.value)
  }
  const portfolioFromPicked = (value: string) => {
    setPortfolioFrom(value)
    getFundsForDateQuery.data = undefined
    getFundsForDate({
      variables: {
        portfolioId: parseInt(value),
        date: commenceDate,
      },
    })
  }
  const handleAmountChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const amount = parseFloat(event.target.value)
    const fund = event.target.id
    const index = commenceAmount.findIndex((elem) => elem.fundId === fund)
    if (index === -1) {
      commenceAmount.push({ fundId: fund, shares: amount })
    } else {
      commenceAmount[index] = { fundId: fund, shares: amount }
    }
    setCommenceAmount(commenceAmount)
  }
  const clearUpdated = () => setUpdated(false)
  const dateStr = commenceDate
  return (
    <div className={styles.commence}>
      <Navigation />
      <h1>Commence funds</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formRow}>
          <label htmlFor="date">Date:</label>
          <input
            type="date"
            id="date"
            onChange={handleDateChanged}
            value={dateStr}
          />
        </div>
        <div className={styles.formRow}>
          <Picker
            label="Portfolio from"
            options={portfolioOptions}
            setter={portfolioFromPicked}
            deselect={deselect}
            resetDeselect={() => setDeselect(false)}
          />
        </div>
        {commenceFunds.map((elem) => (
          <div
            className={`${styles.formRow} ${styles.fundRow}`}
            key={elem.index}
          >
            <div>{elem.name}</div>
            <div className={styles.amountBox}>
              <label htmlFor="amount">Amount:</label>
              <input
                type="number"
                id={elem.index}
                onChange={handleAmountChanged}
                step="0.0001"
              />
            </div>
          </div>
        ))}
        <div className={styles.formRow}>
          <input type="submit" value="Apply changes" id="submitButton" />
        </div>
      </form>
      {updating && <Updating />}
      {updated && (
        <Updated
          message="Commencement added successfully"
          clearer={clearUpdated}
        />
      )}
    </div>
  )
}

export default Commence
