import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useContext,
} from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import Picker from './Picker'
import Loading from './Loading'
import Error from './Error'
import Updating from './Updating'
import Updated from './Updated'
import Navigation from './Navigation'
import { GlobalContext, ContextType } from './GlobalContextProvider'
import styles from './Sale.module.css'

const GET_DATA = gql`
  query {
    getPortfolios {
      name
      portfolioId
      commenced
      linkedId
    }
  }
`

const GET_PRICE = gql`
  query ($fundId: String!, $date: String!, $share: Float!) {
    getPrice(fundId: $fundId, date: $date, share: $share) {
      units
      unitPrice
      totalPrice
    }
  }
`

const GET_FUNDS_FOR_DATE = gql`
  query ($portfolioId: Int!, $date: String!) {
    getFundsForDate(portfolioId: $portfolioId, date: $date) {
      name
      fundId
    }
  }
`

const ADD_SALE = gql`
  mutation (
    $portfolioId: Int!
    $date: String!
    $fundId: String!
    $shares: Float!
    $unitPrice: Float!
  ) {
    addSale(
      portfolioId: $portfolioId
      date: $date
      fundId: $fundId
      shares: $shares
      unitPrice: $unitPrice
    ) {
      portfolioId
      date
      fundId
      shares
      unitPrice
    }
  }
`

interface getData {
  getPortfolios: {
    name: string
    portfolioId: number
    commenced: boolean
    linkedId: number
  }[]
}

interface fundOptionsType {
  name: string
  index: string
}

interface getPriceData {
  getPrice: {
    units: number
    unitPrice: number
    totalPrice: number
  }
}

interface getFundsForDateData {
  getFundsForDate: {
    name: string
    fundId: string
  }[]
}

interface addSaleData {
  portfolioId: number
  date: string
  fundId: string
  shares: number
}

interface SaleProps {}

const Sale: FunctionComponent<SaleProps> = (props: SaleProps) => {
  const today = new Date()
  const [saleDate, setSaleDate] = useState<string>(
    today.toISOString().substring(0, 10)
  )
  const [portfolioFrom, setPortfolioFrom] = useState<string>('')
  const [saleFund, setSaleFund] = useState<string>('')
  const [fundOptions, setFundOptions] = useState<fundOptionsType[]>([])
  const amountRef = useRef<HTMLInputElement>(null)
  const overrideRef = useRef<HTMLInputElement>(null)
  const [sharePriceDisplay, setSharePriceDisplay] = useState<string>('')
  const [sharePrice, setSharePrice] = useState<number>(0)
  const [shareNumber, setShareNumber] = useState<number>(0)
  const typingTimer = useRef<NodeJS.Timeout>()
  const { token } = useContext(GlobalContext) as ContextType
  const getDataQuery = useQuery<getData>(GET_DATA, {
    context: { headers: { authorization: `Bearer ${token}` } },
  })
  const [deselect, setDeselect] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [updated, setUpdated] = useState<boolean>(false)
  const [getPrice, getPriceQuery] = useLazyQuery<getPriceData>(GET_PRICE, {
    context: { headers: { authorization: `Bearer ${token}` } },
  })
  const [getFundsForDate, getFundsForDateQuery] =
    useLazyQuery<getFundsForDateData>(GET_FUNDS_FOR_DATE, {
      context: { headers: { authorization: `Bearer ${token}` } },
    })
  const [addSale, addSaleMutation] = useMutation<addSaleData>(ADD_SALE, {
    context: { headers: { authorization: `Bearer ${token}` } },
  })

  useEffect(() => {
    if (getPriceQuery.data) {
      setSharePriceDisplay(
        `@£${getPriceQuery.data.getPrice.unitPrice.toFixed(
          4
        )} = ${getPriceQuery.data.getPrice.totalPrice.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP',
        })}`
      )
      setSharePrice(getPriceQuery.data.getPrice.unitPrice)
      setShareNumber(getPriceQuery.data.getPrice.units)
    }
  }, [getPriceQuery.data])
  useEffect(() => {
    if (getFundsForDateQuery.data) {
      const fundsToSell = getFundsForDateQuery.data.getFundsForDate.map(
        (el) => {
          return {
            name: el.name,
            index: el.fundId,
          }
        }
      )
      setFundOptions(fundsToSell)
    }
  }, [getFundsForDateQuery.data])
  useEffect(() => {
    if (addSaleMutation.loading) {
      setUpdating(true)
    }
    if (addSaleMutation.data) {
      setSaleFund('')
      setDeselect(true)
      setUpdating(false)
      setUpdated(true)
      setSharePriceDisplay('')
      setSharePrice(0)
      setShareNumber(0)
      if (amountRef.current && overrideRef.current) {
        amountRef.current.disabled = true
        amountRef.current.value = ''
        overrideRef.current.disabled = true
        overrideRef.current.value = ''
      }
    }
  }, [addSaleMutation.data, addSaleMutation.loading])

  if (getDataQuery.loading) return <Loading />
  if (getDataQuery.error) return <Error message={getDataQuery.error.message} />
  if (getDataQuery.data === undefined) {
    return <Error message="No data found" />
  }
  // data is now loaded
  const portfolioOptions = getDataQuery.data.getPortfolios.map((elem) => {
    return {
      name: elem.name,
      index: elem.portfolioId.toString(),
    }
  })
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    addSale({
      variables: {
        portfolioId: parseInt(portfolioFrom),
        date: saleDate,
        fundId: saleFund,
        shares: shareNumber,
        unitPrice: sharePrice,
      },
    })
  }
  const handleDateChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSaleDate(event.target.value)
    if (portfolioFrom) {
      setSaleFund('')
      setDeselect(true)
      setSharePriceDisplay('')
      setSharePrice(0)
      setShareNumber(0)
      if (amountRef.current && overrideRef.current) {
        amountRef.current.disabled = true
        amountRef.current.value = ''
        overrideRef.current.disabled = true
        overrideRef.current.value = ''
      }
      getFundsForDate({
        variables: {
          portfolioId: parseInt(portfolioFrom),
          date: event.target.value,
        },
      })
    }
  }
  const portfolioFromPicked = (value: string) => {
    setPortfolioFrom(value)
    if (saleFund !== '' && amountRef.current && overrideRef.current) {
      amountRef.current.disabled = false
      overrideRef.current.disabled = false
    }
    getFundsForDate({
      variables: {
        portfolioId: parseInt(value),
        date: saleDate,
      },
    })
  }
  const fundPicked = (value: string) => {
    setSaleFund(value)
    if (portfolioFrom !== '' && amountRef.current && overrideRef.current) {
      amountRef.current.disabled = false
      overrideRef.current.disabled = false
    }
  }
  const handleAmountChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (typingTimer.current) {
      clearTimeout(typingTimer.current)
    }
    typingTimer.current = setTimeout(() => {
      getPrice({
        variables: {
          fundId: saleFund,
          date: saleDate,
          share: parseFloat(event.target.value),
        },
      })
    }, 1000)
  }

  const clearUpdated = () => setUpdated(false)

  const handleOverrideChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setSharePrice(parseFloat(event.target.value))
    }
  }

  const dateStr = saleDate

  return (
    <div className={styles.sale}>
      <Navigation />
      <h1>Sell Funds</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formRow}>
          <label htmlFor="date">Date:</label>
          <input
            type="date"
            id="date"
            onChange={handleDateChanged}
            value={dateStr}
          />
        </div>
        <div className={styles.formRow}>
          <Picker
            label="Portfolio"
            options={portfolioOptions}
            setter={portfolioFromPicked}
          />
        </div>
        <div className={styles.formRow}>
          <Picker
            label="Fund to sell"
            options={fundOptions}
            setter={fundPicked}
            deselect={deselect}
            resetDeselect={() => setDeselect(false)}
          />
        </div>
        <div className={`${styles.formRow} ${styles.amountBox}`}>
          <div className={styles.sharePrice}>
            <label htmlFor="amount">Number of shares:</label>
            <span>{sharePriceDisplay}</span>
          </div>
          <input
            type="number"
            id="amount"
            onChange={handleAmountChanged}
            step="0.0001"
            disabled={true}
            ref={amountRef}
          />
        </div>
        <div className={`${styles.formRow} ${styles.amountBox}`}>
          <label htmlFor="override">Override:</label>
          <input
            type="number"
            id="override"
            onChange={handleOverrideChanged}
            step="0.0001"
            disabled={true}
            ref={overrideRef}
          />
        </div>
        <div className={styles.total}>
          Total:{' '}
          {(sharePrice * shareNumber).toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
          })}
        </div>
        <div className={styles.formRow}>
          <input type="submit" value="Apply changes" id="submitButton" />
        </div>
      </form>
      {updating && <Updating />}
      {updated && (
        <Updated
          message="Commencement added successfully"
          clearer={clearUpdated}
        />
      )}
    </div>
  )
}

export default Sale
