import React, {
  FunctionComponent,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react'

export interface ContextType {
  token: string
  setToken: Dispatch<SetStateAction<string>>
}

export const GlobalContext = createContext<ContextType | null>(null)

const GlobalContextProvider: FunctionComponent = ({ children }) => {
  const [token, setToken] = useState(window.localStorage.getItem('token') ?? '')
  return (
    <GlobalContext.Provider value={{ token, setToken }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
