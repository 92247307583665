import React, { FunctionComponent } from 'react'
import styles from './Updating.module.css'

interface UpdatingProps {}

const Updating: FunctionComponent<UpdatingProps> = (props: UpdatingProps) => {
  return (
    <div className={styles.updating}>
      <div className={styles.updatingBox}>Updating...</div>
    </div>
  )
}

export default Updating
