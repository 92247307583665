import React, { useState, useContext } from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import Graph, { graphData } from './Graph'
import Error from './Error'
import { GlobalContext, ContextType } from './GlobalContextProvider'

type Props = {
  fund: string
  setSelect: (fund: string) => void
}

interface fundHistoryData {
  getFundHistory: {
    name: string
    history: {
      date: string
      price: number
    }[]
  }
}

const FundHistory = (props: Props) => {
  const getFundHistory = gql`
    query History($fundId: String!, $days: Int!) {
      getFundHistory(fundId: $fundId, days: $days) {
        name
        history {
          date
          price
        }
      }
    }
  `
  const [period, setPeriod] = useState(31)

  const { token } = useContext(GlobalContext) as ContextType
  const { data, loading, error } = useQuery<fundHistoryData>(getFundHistory, {
    variables: { fundId: props.fund, days: period },
    context: { headers: { authorization: `Bearer ${token}` } },
  })
  if (error) {
    return <Error message={error.message} />
  }
  let points: graphData[] = []
  let name = ''
  if (data !== undefined) {
    points = data.getFundHistory.history.map((entry) => ({
      date: entry.date,
      value: entry.price,
    }))
    name = data.getFundHistory.name
  }
  return (
    <div>
      <Graph
        name={name}
        graphData={points}
        period={period}
        changeDays={(days) => setPeriod(days)}
        closeDialog={() => props.setSelect('')}
        loading={loading || data === undefined}
      />
    </div>
  )
}

export default FundHistory
