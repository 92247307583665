import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ApolloClient from 'apollo-boost'
import './App.css'
import Total from './Total'
import { ApolloProvider } from '@apollo/react-hooks'
import GlobalContextProvider from './GlobalContextProvider'
import Commence from './Commence'
import Purchase from './Purchase'
import Sale from './Sale'

const App = () => {
  const url =
    process.env.NODE_ENV === 'production'
      ? 'https://portfolio.thegreens.co.uk:4000/'
      : 'http://localhost:4000/'
  const client = new ApolloClient({
    uri: url,
  })

  return (
    <ApolloProvider client={client}>
      <GlobalContextProvider>
        <BrowserRouter>
          <div className="App">
            <Switch>
              <Route exact path="/">
                <Total />
              </Route>
              <Route path="/transfer">
                <Commence />
              </Route>
              <Route path="/buy">
                <Purchase />
              </Route>
              <Route path="/sell">
                <Sale />
              </Route>
            </Switch>
          </div>
        </BrowserRouter>
      </GlobalContextProvider>
    </ApolloProvider>
  )
}

export default App
