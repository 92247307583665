import React, {
  FunctionComponent,
  MouseEvent,
  useState,
  useRef,
  useEffect,
} from 'react'
import DialogClose from './DialogClose'
import SelectedPoint from './SelectedPoint'
import { GraphPoint, Point, SelectedPointType } from './Types'
import Loading from './Loading'
import styles from './Graph.module.css'

export interface graphData {
  date: string
  value: number
}

interface GraphProps {
  graphData: graphData[]
  name: String
  period: number
  changeDays: (days: number) => void
  closeDialog: () => void
  loading: boolean
  percentage?: boolean
}

const defaultProps: Partial<GraphProps> = {
  percentage: true,
}

const Graph: FunctionComponent<GraphProps> = (props: GraphProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const parentWidth = useRef(0)
  const points = useRef<GraphPoint[]>([])
  const [selectedPoint, setSelectedPoint] = useState<SelectedPointType | null>(
    null
  )
  const canvasPoints = useRef<Point[]>()
  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    // setPoints([])
    setSelectedPoint(null)
    const newPeriodStr = (event.target as HTMLButtonElement).dataset['period']
    if (newPeriodStr) {
      props.changeDays(parseInt(newPeriodStr))
    }
  }

  const closeClick = (event: MouseEvent<HTMLDivElement>) => {
    props.closeDialog()
  }

  const canvasClick = (event: MouseEvent<HTMLCanvasElement>) => {
    if (canvasRef.current === null) {
      return
    }
    const canvasRect = canvasRef.current.getBoundingClientRect()
    const xPos = event.clientX - canvasRect.left
    const yPos = event.clientY - canvasRect.top
    const foundIndex = (canvasPoints.current as Point[]).reduce(
      (accumulator: number, current: Point, index: number): number => {
        if (xPos > current[0] - 15 && xPos < current[0] + 15) {
          if (yPos > current[1] - 15 && yPos < current[1] + 15) {
            return index
          }
        }
        return accumulator
      },
      -1
    )
    if (foundIndex > -1) {
      let foundPoint: SelectedPointType = { ...points.current[foundIndex] }
      foundPoint.xPos =
        event.clientX - canvasRect.left + canvasRef.current.offsetLeft
      foundPoint.yPos =
        event.clientY - canvasRect.top + canvasRef.current.offsetTop
      setSelectedPoint(foundPoint)
    } else {
      setSelectedPoint(null)
    }
  }
  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas === null) {
      return
    }
    const widthParent = canvas.parentElement?.getBoundingClientRect().width
    if (widthParent !== undefined) {
      parentWidth.current = widthParent
    }
    if (props.graphData.length === 0) {
      return
    }
    const first = props.graphData[0].value
    points.current = props.graphData.map((entry) => ({
      date: entry.date,
      price: entry.value,
      change: props.percentage
        ? 100 * ((entry.value - first) / first)
        : entry.value,
    }))
    canvas.width = canvas.clientWidth
    canvas.height = canvas.clientHeight
    const max = points.current.reduce(
      (acc, curr) => Math.max(acc, curr.change),
      0
    )
    const start = props.percentage ? 0 : points.current[0].change
    const min = points.current.reduce(
      (acc, curr) => Math.min(acc, curr.change),
      start
    )
    const yMargin = 10
    const xMargin = 56
    const yFactor = Math.abs(max - min) / (canvas.height - 2 * yMargin)
    const xFactor = (canvas.width - xMargin - 4) / (points.current.length - 1)
    const context = canvas.getContext('2d')
    if (context === null) {
      return
    }
    context.fillStyle = '#eee'
    context.strokeStyle = '#888'
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.fillStyle = '#000'
    points.current.forEach((point, index) => {
      context.beginPath()
      context.arc(
        index * xFactor + xMargin,
        (max - point.change) / yFactor + yMargin,
        2,
        0,
        Math.PI * 2
      )
      context.fill()
    })
    context.beginPath()
    context.strokeStyle = '#00f'
    const collectPoints: Point[] = []
    points.current.forEach((point, index) => {
      if (index === 0) {
        context.moveTo(
          index * xFactor + xMargin,
          (max - point.change) / yFactor + yMargin
        )
        collectPoints.push([
          index * xFactor + xMargin,
          (max - point.change) / yFactor + yMargin,
        ])
      } else {
        context.lineTo(
          index * xFactor + xMargin,
          (max - point.change) / yFactor + yMargin
        )
        collectPoints.push([
          index * xFactor + xMargin,
          (max - point.change) / yFactor + yMargin,
        ])
      }
    })
    canvasPoints.current = collectPoints
    context.stroke()
    // y-axis
    context.beginPath()
    context.moveTo(xMargin - 2, 0)
    context.lineTo(xMargin - 2, canvas.height)
    context.strokeStyle = '#000'
    context.lineWidth = 1
    context.stroke()
    // set text properties
    context.font = '12px sans-serif'
    context.strokeStyle = '#aaa'
    context.fillStyle = '#000'
    context.textAlign = 'right'
    // max mark
    if (max / yFactor > 20) {
      context.beginPath()
      context.moveTo(xMargin - 16, max / yFactor + yMargin)
      context.lineTo(canvas.width, max / yFactor + yMargin)
      context.stroke()
      const maxStr = max > 1000 ? `${Math.floor(max / 1000)}K` : max.toFixed(2)
      context.fillText(maxStr, xMargin - 16, yMargin + 4)
    }
    // zero
    context.beginPath()
    context.moveTo(xMargin - 16, yMargin)
    context.lineTo(canvas.width, yMargin)
    context.stroke()
    context.fillText('0', xMargin - 16, max / yFactor + yMargin + 4)
    // min mark
    if (min / yFactor < -20) {
      context.beginPath()
      context.moveTo(xMargin - 16, canvas.height - yMargin)
      context.lineTo(canvas.width, canvas.height - yMargin)
      context.stroke()
      context.fillText(
        Math.abs(min).toFixed(2),
        xMargin - 16,
        canvas.height - yMargin + 4
      )
    }
  }, [props.graphData, props.percentage])

  const buttonParams = [
    { days: 8, label: '1 Week' },
    { days: 31, label: '1 Month' },
    { days: 183, label: '6 months' },
    { days: 365, label: '1 Year' },
    { days: 730, label: '2 Years' },
    { days: 1_826, label: '5 Years' },
  ]

  return (
    <div className={styles.graph}>
      <div className="historyOverlay" />
      {selectedPoint !== null && (
        <SelectedPoint
          selectedPoint={selectedPoint}
          parentWidth={parentWidth.current}
          percentage={props.percentage!}
        />
      )}
      <DialogClose closeFunction={closeClick} />
      <div className={styles.historyWrap}>
        <div className={styles.fundName}>{props.name}</div>
        <canvas
          className={styles.historyInner}
          ref={canvasRef}
          onClick={canvasClick}
        />
        {props.loading && <Loading />}
        <div className={styles.buttons}>
          {buttonParams.map((entry, index) => (
            <button
              className={
                props.period === entry.days
                  ? `${styles.periodButton} ${styles.selectedPeriod}`
                  : styles.periodButton
              }
              onClick={handleButtonClick}
              data-period={entry.days.toString()}
              key={index}
            >
              {entry.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

Graph.defaultProps = defaultProps

export default Graph
