import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styles from './Navigation.module.css'

interface NavigationProps {}

const Navigation: FunctionComponent<NavigationProps> = (
  props: NavigationProps
) => {
  return (
    <div className={styles.navigation}>
      <Link to="/">Home</Link>
      <Link to="/transfer">Transfer funds</Link>
      <Link to="/buy">Buy funds</Link>
      <Link to="/sell">Sell funds</Link>
    </div>
  )
}

export default Navigation
