import React, { useState, useEffect, useRef, FunctionComponent } from 'react'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'
import Error from './Error'
import Loading from './Loading'
import styles from './Password.module.css'

const CHECK_PASSWORD = gql`
  query CheckPW($password: String!) {
    checkPassword(password: $password) {
      token
    }
  }
`
interface PasswordProps {
  setPasswordAccepted: (token: string) => void
}

const Password: FunctionComponent<PasswordProps> = (props) => {
  const [checkPW, { data, loading, error }] = useLazyQuery(CHECK_PASSWORD)
  const [passwordError, setPasswordError] = useState(false)
  const passwordRef = useRef<HTMLInputElement>(null)
  const { setPasswordAccepted } = props
  useEffect(() => {
    if (data === undefined) {
      return
    }
    const token = data?.checkPassword?.token
    if (token !== null) {
      if (token === '') {
        setPasswordError(true)
      } else {
        window.localStorage.setItem('token', data.checkPassword.token)
        setPasswordAccepted(data.checkPassword.token)
      }
    }
  }, [data, setPasswordAccepted])
  if (error) {
    return <Error message={`Error ... ${error.message}`} />
  }
  return (
    <div>
      <h1 className={styles.passwordHeader}>Password needed to access data</h1>
      <div className={styles.passwordForm}>
        <label>Enter password</label>
        <input type="password" id="passwordField" ref={passwordRef} />
        <button
          onClick={() => {
            checkPW({ variables: { password: passwordRef.current?.value } })
          }}
        >
          Send
        </button>
        {passwordError && (
          <div className={styles.errorMessage}>Incorrect password</div>
        )}
      </div>
      {loading && <Loading />}
    </div>
  )
}

export default Password
