import React from 'react'
import styles from './Loading.module.css'

const Loading = () => {
  return (
    <div className={styles.loadingBox}>
      <svg width="86px" height="86px" viewBox="0 0 86 86">
        <rect x="0" y="0" width="22" height="86" fill="#1fa2ff">
          <animate
            attributeName="height"
            values="86;20;86"
            begin="0s"
            dur="1s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            values="0;33;0"
            begin="0s"
            dur="1s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="33" y="0" width="22" height="86" fill="#12d8fa">
          <animate
            attributeName="height"
            values="86;20;86"
            begin="0.2s"
            dur="1s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            values="0;33;0"
            begin="0.2s"
            dur="1s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="66" y="0" width="22" height="86" fill="#06ffcb">
          <animate
            attributeName="height"
            values="86;20;86"
            begin="0.4s"
            dur="1s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            values="0;33;0"
            begin="0.4s"
            dur="1s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  )
}

export default Loading
