import React, { useState, useEffect, useContext } from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import Portfolio from './Portfolio'
import Password from './Password'
import Error from './Error'
import { format, formatDate } from './Utils'
import { Investment, PortfolioType } from './Types'
import styles from './Total.module.css'
import Navigation from './Navigation'
import ValueHistory from './ValueHistory'
import { GlobalContext, ContextType } from './GlobalContextProvider'

const GET_PORTFOLIOS = gql`
  query {
    getPortfolioLatest {
      updateDate
      portfolios {
        name
        investments {
          fundName
          fundId
          currentValue
          startValue
        }
      }
    }
  }
`
const Total = () => {
  const [showValueHistory, setShowValueHistory] = useState<boolean>(false)
  const [showPasswordDialog, setShowPasswordDialog] = useState<boolean>(false)
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  const { token, setToken } = useContext(GlobalContext) as ContextType
  const { data, loading, error } = useQuery(GET_PORTFOLIOS, {
    context: { headers: { authorization: `Bearer ${token}` } },
  })
  const [
    refetchGetPortfolios,
    { loading: lazyLoading, error: lazyError, data: lazyData },
  ] = useLazyQuery(GET_PORTFOLIOS, {
    context: { headers: { authorization: `Bearer ${token}` } },
  })

  const closeValueHistory = () => {
    setShowValueHistory(false)
  }

  useEffect(() => {
    if (error) {
      if (error.message === 'GraphQL error: Not logged in') {
        setShowPasswordDialog(true)
      } else {
        setShowErrorDialog(true)
      }
    }
  }, [error])

  useEffect(() => {
    if (lazyError) {
      if (lazyError.message === 'GraphQL error: Not logged in') {
        setShowPasswordDialog(true)
      } else {
        setShowErrorDialog(true)
      }
    }
  }, [lazyError])

  const setPasswordAccepted = (token: string) => {
    setShowPasswordDialog(false)
    setToken(token)
    refetchGetPortfolios()
  }

  if (loading || lazyLoading) {
    return <p>Loading..</p>
  }

  if (showErrorDialog) {
    let message = ''
    if (error) {
      message = error.message
    }
    if (lazyError) {
      message = lazyError.message
    }
    return <Error message={message} />
  }

  if (showPasswordDialog) {
    return <Password setPasswordAccepted={setPasswordAccepted} />
  }

  let dataToUse = data

  if (lazyData !== null && lazyData !== undefined) {
    if (Object.entries(lazyData).length > 0) {
      dataToUse = lazyData
    }
  }

  if (dataToUse === undefined) {
    return null
  }

  if (Object.entries(dataToUse).length > 0) {
    let currentTotal = 0
    let startTotal = 0
    return (
      <div>
        <nav>
          <Navigation />
        </nav>
        {showValueHistory && <ValueHistory closeDialog={closeValueHistory} />}
        {dataToUse.getPortfolioLatest.portfolios.map(
          (portfolio: PortfolioType, index: number) => {
            currentTotal += portfolio.investments.reduce(
              (accumulator, current: Investment) =>
                accumulator + current.currentValue,
              0
            )
            startTotal += portfolio.investments.reduce(
              (accumulator, current) => accumulator + current.startValue,
              0
            )
            return <Portfolio portfolio={portfolio} key={index} />
          }
        )}
        <div className={styles.grandTotal}>
          <div>Grand Total</div>
          <div
            className={`${styles.grandTotalNumber} ${styles.clickable}`}
            onClick={() => setShowValueHistory(true)}
          >
            {format(currentTotal)}
          </div>
          <div className={styles.grandTotalNumber}>{format(startTotal)}</div>
          <div className={styles.grandTotalNumber}>
            {format(currentTotal - startTotal)}
          </div>
        </div>
        <div className={styles.updateDate}>
          Last update {formatDate(data.getPortfolioLatest.updateDate)}
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <Password setPasswordAccepted={setPasswordAccepted} />
      </div>
    )
  }
}

export default Total
