import React, { useRef, useEffect } from 'react'
import { SelectedPointType } from './Types'
import styles from './SelectedPoint.module.css'

const dateFormat = (date: string) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const day = date.substr(8, 2)
  const month = parseInt(date.substr(5, 2))
  const year = date.substr(0, 4)
  return `${months[month - 1]} ${day},${year}`
}

type Props = {
  selectedPoint: SelectedPointType
  parentWidth: number
  percentage: boolean
}

const SelectedPoints = (props: Props) => {
  const selectedPointRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let left =
      props.selectedPoint?.xPos === undefined ? 0 : props.selectedPoint?.xPos
    let top =
      props.selectedPoint?.yPos === undefined ? 0 : props.selectedPoint?.yPos
    top = top + 5
    const width = selectedPointRef.current?.getBoundingClientRect().width
    if (width === undefined || selectedPointRef.current === null) {
      return
    }
    left = left - width / 2
    if (left < 1 + width / 2) {
      left = 0
    } else {
      if (left + width > props.parentWidth) {
        left = props.parentWidth - width - 1
      }
    }
    selectedPointRef.current.style.left = `${left}px`
    selectedPointRef.current.style.top = `${top}px`
  })

  return (
    <div className={styles.selectedPoint} ref={selectedPointRef}>
      <span className={styles.pointDetail}>
        {dateFormat(props.selectedPoint.date)}
      </span>
      {props.percentage && (
        <span className={styles.pointDetail}>{props.selectedPoint.price}</span>
      )}
      <span className={styles.pointDetail}>
        {props.percentage ? '' : '£'}
        {props.selectedPoint.change.toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        {props.percentage ? ' %' : ''}
      </span>
    </div>
  )
}

export default SelectedPoints
