import React, { FunctionComponent, useEffect } from 'react'
import styles from './Updated.module.css'

interface UpdatedProps {
  message: string
  clearer: () => void
}

const Updated: FunctionComponent<UpdatedProps> = (props: UpdatedProps) => {
  const { clearer } = props
  useEffect(() => {
    const timer = setTimeout(() => {
      clearer()
    }, 10_000)
    return () => clearTimeout(timer)
  }, [clearer])
  return <div className={styles.updated}>{props.message}</div>
}

export default Updated
