export const formatDate = (inString: string) => {
  const yearTest = /^\d\d\d\d-\d\d-\d\d$/
  if (!yearTest.test(inString)) {
    return inString
  }
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  let day = inString.substr(8,2)
  day = (day.substr(0, 1) === '0') ? day.substr(1, 1) : day
  return `${months[parseInt(inString.substr(5,2)) - 1]} ${day}, ${inString.substr(0,4)}`
}

export const format = (number: number) => {
  let sign1 = ''
  let sign2 = ''
  if (number < 0) {
    sign1 = '('
    sign2 = ')'
  }
  return `${sign1}${Math.abs(number / 100).toLocaleString('en-GB',{style:'currency', currency:'GBP'})}${sign2}`
}

const funcn = () => {
  console.log('default function')
}

export {funcn as default}
